import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import {
  faPhoneSquareAlt,
  faEnvelopeSquare,
  faMapMarked,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Fade from 'react-reveal/Fade';

const SecondPage = () => (
  <Layout>
    <SEO title="Contact" description="Contact Brittany Kleszynski, DVM with Pawprint Communications to discuss writing projects for medical and veterinary applications."/>
    <div className="hero hero-subpage office">
    <Fade bottom>

      <div className="container p-0 text-center">
        <div className="hero-text-heading">GET IN TOUCH</div>
      </div>
      </Fade>
    </div>
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-12 text-center py-5">
          <div className="">
          <p><FontAwesomeIcon className="standoutIcon d-inline align-middle p-2" icon={faEnvelopeSquare} /><br/>info@pawprintmedicalwriting.com</p>

          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default SecondPage
